<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="7" md="9">
        <div class="mb-12">
          <div class="text-h5">
            Analytics - Vorschau
          </div>
          <div class="body-1">
            Vorschau einer ausgewählten Auswertungsmöglichkeit
          </div>
        </div>

        <div class="mb-4">
          <v-row>
            <v-col cols="12" md="auto">
              <v-select
                hide-details
                dense
                solo-inverted
                flat
                :items="['2019', '2020', '2021']"
                readonly
                value="2020"
                label="Jahr"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <v-select
                hide-details
                dense
                solo-inverted
                flat
                :items="['Dosen Weltmeister GmbH']"
                readonly
                value="Dosen Weltmeister GmbH"
                label="Mandant"
              />
            </v-col>
          </v-row>
        </div>

        <div class="mb-3">
          <div class="text-h6 mb-2">Nutzung der Belegtypen</div>
          <v-progress-linear
            color="blue"
            :value="30"
            height="25"
            class="mb-1 rounded-xl"
            >30%</v-progress-linear
          >
          <div class="mb-2">Papierbelege</div>
          <v-progress-linear
            color="purple"
            :value="50"
            height="25"
            class="mb-1 rounded-xl"
            >50%</v-progress-linear
          >
          <div class="mb-2">Papierbelege die gescannt werden</div>
          <v-progress-linear
            color="green"
            :value="20"
            height="25"
            class="mb-1 rounded-xl"
            >20%</v-progress-linear
          >
          <div class="mb-2">Digitale Belege</div>
        </div>

        <v-alert type="info" text dense>
          <div class="font-weight-bold">
            Beratungspotential
          </div>
          <div>
            Aus der Auswertung des Mandanten geht hervor, dass dieser den
            Großteil seiner Belege analog verwaltet. Durch eine entsprechende
            Digitalisierung könnte der Klient kosten sparen und die
            Dokumentation vereinfachen.
          </div>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    items: [
      {
        name: "Mandantenübersicht",
        description:
          "lorem ipsum. lorem ipsum.lorem ipsum.lorem ipsum.lorem ipsum.",
        img: "",
        to: "DemoClientIndex"
      },
      {
        name: "Mandantenansicht",
        description: "lorem ipsum.",
        img: "",
        to: "DemoClientShow"
      },
      {
        name: "Verfahrensdokumentation",
        description: "lorem ipsum.",
        img: "",
        to: "DemoProcessDocumentationForm"
      },
      {
        name: "Analytics",
        description: "lorem ipsum.",
        img: "",
        to: "DemoAnalytics"
      }
    ]
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
