<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <v-btn
          color="primary"
          :to="{ name: 'DemoDashboard' }"
          exact
          plain
          class="mb-8 pa-0"
        >
          <v-icon left>mdi-chevron-left</v-icon>
          Zurück zur Übersicht
        </v-btn>
        <v-card class="mb-10">
          <v-card-title>
            Mandanten, welche dieses Jahr noch keine Verfahrensdokumentation
            abgelegt haben
          </v-card-title>
          <v-card-text>
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filter"
              label="Suchen"
              solo-inverted
              flat
              hide-details
              style="max-width: 500px"
            />
          </v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            sort-by="last_at"
            :search="filter"
          >
            <template v-slot:item.last_at="{ item }">
              {{ item.last_at_text }}
            </template>
            <template v-slot:item.action="{}">
              <v-btn icon color="primary">
                <v-icon color="primary">mdi-eye-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

        <v-card>
          <v-card-title>
            Mandanten, welche dieses Jahr noch keine Verfahrensdokumentation
            abgelegt haben
          </v-card-title>

          <v-card-text>
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filterThisYear"
              label="Suchen"
              solo-inverted
              flat
              hide-details
              style="max-width: 500px"
            />
          </v-card-text>

          <v-data-table
            :headers="headers"
            :items="itemsThisYear"
            :search="filterThisYear"
          >
            <template v-slot:item.last_at="{ item }">
              {{ item.last_at_text }}
            </template>
            <template v-slot:item.action="{}">
              <v-btn icon color="primary">
                <v-icon color="primary">mdi-eye-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    filter: "",
    filterThisYear: "",
    headers: [
      { text: "Name", value: "name", align: "start", width: "50%" },
      { text: "Letzte Dokumentation", value: "last_at" },
      { text: "Aktion", value: "action", align: "end", sortable: false }
    ],
    items: [
      {
        name: "Dosen Weltmeister GmbH",
        last_at: "2020-05-20",
        last_at_text: "20.05.2020"
      },
      {
        name: "Ideenreiche Wertpapiere AG",
        last_at: "2020-06-21",
        last_at_text: "21.06.2020"
      },
      {
        name: "Strategische Futterbeschaffung OHG",
        last_at: "2020-12-24",
        last_at_text: "24.12.2020"
      }
    ],
    itemsThisYear: [
      {
        name: "OpenDoor Kanzlei mbH",
        last_at: "2021-05-21",
        last_at_text: "20.05.2021"
      },
      {
        name: "Tiger Entwickler GbR",
        last_at: "2020-06-21",
        last_at_text: "21.06.2021"
      },
      {
        name: "Obststand Meister e.K.",
        last_at: "2021-12-24",
        last_at_text: "24.12.2021"
      }
    ]
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
