<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <v-btn
          color="primary"
          :to="{ name: 'DemoDashboard' }"
          exact
          plain
          class="mb-8 pa-0"
        >
          <v-icon left>mdi-chevron-left</v-icon>
          Zurück zur Übersicht
        </v-btn>

        <demo-client-show-info class="mb-6" />

        <v-card class="mb-6">
          <v-card-text>
            <div class="text-overline">
              Ansprechpartner
            </div>
          </v-card-text>
          <v-data-table
            :items="company.contactPersons"
            :headers="contactPersonsHeaders"
            hide-default-footer
          ></v-data-table>
          <v-card-actions class="pa-4">
            <v-btn depressed>
              Ansprechpartner hinzufügen
              <v-icon right>
                mdi-account-plus-outline
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card>
          <v-card-text>
            <div class="text-overline">
              Verfahrensdokumentationen
            </div>
          </v-card-text>
          <v-data-table
            :items="company.documentations"
            :headers="documentationHeaders"
            hide-default-footer
          >
            <template v-slot:item.last_at="{ item }">
              {{ item.created_at_text }}
            </template>
            <template v-slot:item.action="{}">
              <v-btn icon color="primary" class="mr-2">
                <v-icon color="primary">mdi-eye-outline</v-icon>
              </v-btn>
              <v-btn icon color="primary">
                <v-icon color="primary">mdi-download-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-card-actions class="pa-4">
            <v-btn color="primary" dark>
              Dokumentation hinzufügen
              <v-icon right>
                mdi-text-box-plus-outline
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    contactPersonsHeaders: [
      { text: "Vorname", value: "first_name" },
      { text: "Nachname", value: "last_name" },
      { text: "E-Mail-Adresse", value: "email" },
      { text: "Telefon", value: "phone" }
    ],
    documentationHeaders: [
      { text: "Erstellt", value: "created_at" },
      { text: "Berater", value: "consultant" },
      { text: "Kontaktperson", value: "contactPerson" },
      { text: "Aktion", value: "action", align: "end", sortable: false }
    ]
  }),
  computed: {
    company() {
      return this.$store.getters["demo/company"];
    }
  },
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
