<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="9">
        <v-row>
          <v-col cols="12" md="4" v-for="(item, index) in items" :key="index">
            <v-hover v-slot="{ hover }" class="transition-ease-in-out">
              <v-card
                :elevation="hover ? 8 : 2"
                :to="{ name: item.to }"
                class="d-flex justify-space-between flex-column"
                height="100%"
              >
                <div>
                  <v-card-title>{{ item.name }}</v-card-title>
                  <v-card-text>{{ item.description }}</v-card-text>
                </div>
                <v-card-actions class="pa-4">
                  <v-btn color="primary" block dark>
                    {{ item.name }}
                    <v-icon right>
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    items: [
      {
        name: "Mandantenübersicht",
        description:
          "Finden Sie schnell und einfach heraus, welcher Ihrer Klienten noch keine Verfahrensdokumentation abgelegt hat.",
        img: "",
        to: "DemoClientIndex"
      },
      {
        name: "Mandantenansicht",
        description:
          "Überprüfen Sie die Daten und Verfahrensdokumentationen Ihres Mandanten.",
        img: "",
        to: "DemoClientShow"
      },
      {
        name: "Verfahrensdokumentation",
        description:
          "Legen Sie eine neue Verfahrensdokumentation an und geben Sie diese im Anschluss GoBD-konform frei.",
        img: "",
        to: "DemoProcessDocumentationForm"
      },
      {
        name: "Analytics",
        description:
          "Werten Sie die Daten aus vergangenen Verfahrensdokumentationen aus und erfahren Sie schnell, wo es Potential gibt.",
        img: "",
        to: "DemoAnalytics"
      }
    ]
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
