<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="7" md="9">
        <div class="text-h5">
          Verfahrensdokumentation - Vorschau
        </div>
        <div class="body-1 mb-12">
          Diese Vorschau bietet einen Auszug zukünftiger
          Bearbeitungsmöglichkeiten
        </div>

        <v-switch
          v-model="isReleased"
          :label="
            isReleased ? 'Vorschau nach Freigabe' : 'Vorschau der Bearbeitung'
          "
        />
        <v-tabs background-color="transparent" class="mb-4" v-model="tab">
          <v-tab>Übersicht</v-tab>
          <v-tab>Inhalt</v-tab>
        </v-tabs>

        <div>
          <v-tabs-items
            v-model="tab"
            style="background-color: transparent;"
            class="pa-1 ma-n1"
          >
            <v-tab-item>
              <v-card>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="12" md="auto">
                      <div>
                        <v-progress-circular
                          :value="60"
                          color="primary"
                          size="150"
                          width="20"
                          >60%</v-progress-circular
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" md="auto">
                      <div class="mb-2">
                        <v-chip color="primary" dark small v-if="!isReleased">
                          Noch nicht freigegeben
                        </v-chip>
                        <v-chip color="primary" dark small v-else>
                          <v-icon small left>mdi-lock-outline</v-icon>
                          Freigegeben - gesperrt
                        </v-chip>
                      </div>
                      <div class="text-h6 mb-2">
                        Verfahrensdokumentation vom 25.06.2021
                      </div>
                      <div>
                        <div class="text-overline">
                          Bearbeiter
                        </div>
                        <div class="d-flex align-center">
                          <v-avatar
                            class="white--text"
                            color="primary"
                            size="35"
                          >
                            <img src="@/assets/lothar.jpg" alt="Profilbild" />
                          </v-avatar>
                          <div class="ml-2">
                            Lothar Matthäus (10)
                          </div>
                        </div>
                      </div>
                      <div v-if="isReleased" class="mt-4">
                        <v-btn color="primary">
                          Herunterladen als PDF/A-Datei
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <demo-client-show-info class="mt-6" />
            </v-tab-item>

            <v-tab-item>
              <demo-v-doc-content :is-released="isReleased" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {},
  data: () => ({
    isReleased: false,
    tab: 0,
    items: [
      {
        name: "Mandantenübersicht",
        description:
          "lorem ipsum. lorem ipsum.lorem ipsum.lorem ipsum.lorem ipsum.",
        img: "",
        to: "DemoClientIndex"
      },
      {
        name: "Mandantenansicht",
        description: "lorem ipsum.",
        img: "",
        to: "DemoClientShow"
      },
      {
        name: "Verfahrensdokumentation",
        description: "lorem ipsum.",
        img: "",
        to: "DemoProcessDocumentationForm"
      },
      {
        name: "Analytics",
        description: "lorem ipsum.",
        img: "",
        to: "DemoAnalytics"
      }
    ]
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {}
};
</script>

<style scoped></style>
